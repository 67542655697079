import Register from './Register';

function App() {

  return (
    <main className="App">
      <Register />
    </main>
  );
}

export default App;